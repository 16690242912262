/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'MyApply',
  components: {
    Scroll: Scroll
  },
  data: function data() {
    return {
      tabList: [{
        title: '待审批',
        value: 1
      }, {
        title: '已通过',
        value: 2
      }, {
        title: '已驳回',
        value: 3
      }, {
        title: '已撤销',
        value: 4
      }],
      active: this.$utils.Store.getItem('applyItem') ? this.$utils.Store.getItem('applyItem') : 0,
      list: [],
      //申请记录数组
      curPage: 1,
      //当前页
      totalPage: 0,
      //总页数
      pageNum: 20,
      //每页条数
      lx: this.$utils.Store.getItem('applyLx') ? this.$utils.Store.getItem('applyLx') : '0,1',
      //类型，默认为待审核
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      initFlag: true
    };
  },
  computed: {},
  methods: {
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.list = [];
      this.curPage = 1;
      this.getMyApplication();
    },
    onPullingUp: function onPullingUp() {
      if (this.isPullUp) return;
      this.isPullUp = true;
      this.curPage++;

      if (this.curPage > this.totalPage) {
        this.updateScroll();
        return;
      }

      this.getMyApplication();
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    getMyApplication: function getMyApplication() {
      var _this = this;

      this.emptyShow = false;
      this.$utils.Tools.loadShow();

      if (!Array.isArray(this.lx)) {
        this.lx = this.lx.split(',');
      }

      this.$api.Appr.applyList({
        pageIndex: this.curPage,
        pageSize: this.pageNum,
        search: {
          statusList: this.lx,
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code !== _this.$code.success) {
          if (+code === 605) {
            if (_this.list.length === 0) {
              _this.emptyShow = true;
            }
          }

          _this.updateScroll(false);
        } else {
          var _this$list;

          (_this$list = _this.list).push.apply(_this$list, _toConsumableArray(data.records));

          _this.totalPage = data.totalPage;

          if (_this.list.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    onItemClick: function onItemClick(index) {
      this.active = index;
      this.$utils.Store.setItem('applyItem', this.active);

      if (index == 0) {
        this.lx = '0,1';
        this.curPage = 1;
        this.list = [];
        this.$utils.Store.setItem('applyLx', this.lx);
        this.getMyApplication();
      } else if (index == 1) {
        this.lx = '2';
        this.curPage = 1;
        this.list = [];
        this.$utils.Store.setItem('applyLx', this.lx);
        this.getMyApplication();
      } else if (index == 2) {
        this.lx = '3';
        this.curPage = 1;
        this.list = [];
        this.$utils.Store.setItem('applyLx', this.lx);
        this.getMyApplication();
      } else {
        this.lx = '4';
        this.curPage = 1;
        this.list = [];
        this.$utils.Store.setItem('applyLx', this.lx);
        this.getMyApplication();
      }
    },
    cellClick: function cellClick(xh, type) {
      //type:假类   0 请假；1 出差；2 外出；3 加班；4 调班；5 补出勤; 6 外勤 8销假
      var state = encodeURI(xh + ',1');

      switch (type //genre：类型  1：申请的  2：审批的  3：抄送的
      ) {
        case 0:
          this.$router.push({
            name: 'leaveInfo',
            params: {
              state: state
            }
          });
          break;

        case 1:
          this.$router.push({
            name: 'evectionInfo',
            params: {
              state: state
            }
          });
          break;

        case 2:
          this.$router.push({
            name: 'businessInfo',
            params: {
              state: state
            }
          });
          break;

        case 3:
          this.$router.push({
            name: 'overtimeInfo',
            params: {
              state: state
            }
          });
          break;

        case 4:
          this.$router.push({
            name: 'changeInfo',
            params: {
              state: state
            }
          });
          break;

        case 5:
          this.$router.push({
            name: 'repairInfos',
            params: {
              state: state
            }
          });
          break;

        case 6:
          this.$router.push({
            name: 'legWorkInfo',
            params: {
              state: state
            }
          });
          break;

        case 8:
          this.$router.push({
            name: 'callInfo',
            params: {
              state: state
            }
          });
          break;
      }
    }
  },
  created: function created() {
    this.getMyApplication();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};